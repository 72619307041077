import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';

const Toast = {
  install(v) {
    v.use(ToastPlugin);
    // eslint-disable-next-line no-param-reassign
    v.prototype.$errorToast = (message) => v.$toast.error(message, {
      type: 'error',
      dismissible: true,
      duration: 5000,
    });
    // eslint-disable-next-line no-param-reassign
    v.$errorToast = v.prototype.$errorToast;
    console.log('Plugin VueToast installed');
  },
};

export default Toast;
